import Vue from 'vue'
import pageMixin from '../../mixins/page'

export default Vue.extend({
  name: 'XpHomePage',
  mixins: [pageMixin],
  data () {
    return {
      childrenPageData: [],
      contentPageData: null
    }
  },
  created () {
    console.log('XpHomePage')
    // this.$xp.history.addHomePage(this.$route.params.id, this.$route.path, this.pageData.Title)
    if (this.pageData.NfcPageId) {
      console.log('XpHomePage.created() set NfcPageID=', this.pageData.NfcPageId)
      window.localStorage.setItem('nfcPageId', this.pageData.NfcPageId)
    }
  },
  mounted () {
    this.childrenPageData = JSON.parse(JSON.stringify(this.pageData.children))
    if (this.childrenPageData.length > 0) {
      // show nav only when tab items > 0
      this.$xp.navigation.preventAutoHide()
      this.$xp.navigation.showNav()
    } else if (this.childrenPageData.length === 1 && this.childrenPageData[0].Class === 'XpSplashScreen') {
      // hide navigation (splash screen is used for example)
      this.$xp.navigation.preventAutoHide()
      this.$xp.navigation.hideNav()
    }
    // this.$xp.navigation.setTabs(this.childrenPageData)
    if (this.childrenPageData.length > 1 && this.$route.query.activeTabId) {
      this.$xp.navigation.setActiveTabId(parseInt(this.$route.query.activeTabId))
    } else if (this.$xp.history.historyParams[`homeActiveTab${this.$route.params.id}`]) {
      this.$xp.navigation.setActiveTabId(parseInt(this.$xp.history.historyParams[`homeActiveTab${this.$route.params.id}`]))
    } else if (this.childrenPageData.length && !this.$xp.navigation.activeTab) {
      this.$xp.navigation.setActiveTabId(this.childrenPageData[0].ID)
    }
    console.log(`XpHomePage redirecting to /page/${this.$xp.navigation.activeTabId}`)
    this.$xp.history.replace(this.$xp.navigation.activeTabId, `/page/${this.$xp.navigation.activeTabId}`)
    this.$router.replace(`/page/${this.$xp.navigation.activeTabId}`)
  },
  methods: {
  },
  render () {
    return null
  }
})
